<template>
  <div class="subject_warp">
    <div class="form">
      <div class="date">
        <span>
          学生近期总提交试卷数统计
        </span>
        <div class="grade">
          <!-- <el-select style="width:110px"
                     v-model="grade_id"
                     @change="getClassList"
                     clearable
                     placeholder="年级">
            <el-option v-for="item in $parent.gradeList"
                       :key="item.grade_id"
                       :label="item.grade_name"
                       :value="item.grade_id">
            </el-option>
          </el-select> -->

          <el-select style="width:110px"
                     v-model="graduated_id"
                     @change="getClassList"
                     clearable
                     filterable
                     placeholder="届">
            <el-option v-for="item in $parent.jieList"
                       :key="item.graduated_id"
                       :label="item.year_desc"
                       :value="item.graduated_id">
            </el-option>
          </el-select>
        </div>

        <div class="class">
          <el-select style="width:110px"
                     v-model="class_id"
                     @change="clear"
                     clearable
                     filterable
                     placeholder="班级">
            <el-option v-for="item in classList"
                       :key="item.id"
                       :label="item.class_name"
                       :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>

    </div>
    <div id="myChartYuan"
         :style="{width: '100%', height: '40vh'}"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      subjectList: [],
      teacher: '',
      class_id: '',
      graduated_id: '',
      list: [],
      value: [],
      classList: [],
      label: []
    }
  },
  created () {
  },
  methods: {
    clear () {
      let myChart = this.$echarts.init(document.getElementById('myChartYuan'))
      myChart.dispose()
      this.getList()
    },
    getClassList () {
      this.class_id = ''
      this.classList = []
      this.clear()
      if (this.graduated_id == '') return
      this.$http({
        url: '/api/v1/special/get_class',
        method: 'get',
        params: {
          school_id: this.$parent.school_id,
          graduated_id: this.graduated_id
        }
      }).then(res => {
        this.classList = res.data.list
      })
    },
    drawLine () {
      var myChart = this.$echarts.init(document.getElementById('myChartYuan'));
      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.label
        },
        yAxis: {
          type: 'value',
          min: 0
        },
        grid: {
          bottom: '15%',
          top: '10%',
        },
        color: ['#3F9EFF'],
        series: [
          {
            data: this.value,
            type: 'line',
            areaStyle: {}
          }
        ]
      })
    },
    getList () {
      let params = {
        school_id: this.$parent.school_id,
        graduated_id: this.graduated_id,
        class_id: this.class_id,
      }
      this.$http({
        url: '/api/v1/main/get_user_weak_paper',
        method: 'get',
        params
      }).then(res => {
        this.list = res.data.list

        let label = []
        let value = []
        this.list.forEach(item => {
          label.push(`${item.start_time}~${item.end_time}`)
          value.push(item.score)
        })
        this.value = value
        this.label = label
        this.$nextTick(() => {
          this.drawLine()
        })
      })
    },
    // clickItem (id) {
    //   this.subject_id = id
    //   this.getList()
    // }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  .isActive {
    border: 1px solid #3f9eff !important;
    color: #3f9eff !important;
  }
  .subject_warp {
    background: white;
    padding: 10px;
    margin: 1%;
  }
  .subject_list {
    border: 1px solid #d9d9d9;
    display: inline-block;
    span {
      display: inline-block;
      border-right: 1px solid #d9d9d9;
      padding: 5px 10px;
      cursor: pointer;
      color: #000000;
    }
  }
  .form {
    display: flex;
    .date {
      flex: 1;
      span {
        width: 120px;
        font-weight: bold;
        color: rgba(8, 8, 8, 0.85);
        font-size: 16px;
      }
      div {
        display: inline-block;
        width: 110px;
        margin-right: 10px;
      }
    }
    .subject {
      display: flex;
    }
  }
}
@media screen and (max-width: 1200px) {
  .subject_warp {
    background-color: white;
    margin: 1.5%;
    border-radius: 5px;
    .form {
      overflow: hidden;
      .date {
        display: flex;
        align-items: center;
        margin-top: 10px;
        span {
          font-size: 16px;
          font-weight: bold;
          color: rgba(8, 8, 8, 0.85);
          margin-left: 14px;
        }
        .grade {
          margin-left: 25%;
        }
        .class {
          margin-left: 10px;
        }
        .el-select {
          width: 80px !important;
        }
      }
    }
  }
}
</style>