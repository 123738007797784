<template>
  <div>
    <div v-if='showWelcome'>
      <welcome />
    </div>
    <div v-else>
      <div class="checkSchool"
           v-if="home_id == 1">
        <span>选择学校:</span>
        <el-select style="width:100%"
                   v-model="school_id"
                   filterable
                   placeholder="是否可用">
          <div>
            <el-option v-for="item in schoolList"
                       :key="item.school_id"
                       :label="item.school_name"
                       :value="item.school_id">
            </el-option>
          </div>
        </el-select>
      </div>
      <div class="info_warp">
        <div class="left_tag">
          <div class="tag">
            <div class="number">
              <div>
                {{info.teacher_num}}
              </div>
              <div>
                教师总数
              </div>
            </div>
            <div class="tubiao">
              <img src="@/assets/homepage/icon-studentAll.png"
                   alt="">
            </div>
          </div>
          <div class="tag">
            <div class="number">
              <div>
                {{info.teacher_paper_num}}
              </div>
              <div>
                教师组卷数
              </div>
            </div>
            <div class="tubiao">
              <img src="@/assets/homepage/icon-xueshengzuoti.png"
                   alt="">
            </div>
          </div>
          <div class="tag">
            <div class="number">
              <div>
                {{info.teacher_judge_num}}
              </div>
              <div>
                教师改卷数
              </div>
            </div>
            <div class="tubiao">
              <img src="@/assets/homepage/icon-submitPaper.png"
                   alt="">
            </div>
          </div>
          <div class="tag">
            <div class="number">
              <div>
                {{info.edu_file_num}}
              </div>
              <div>
                校本资源数
              </div>
            </div>
            <div class="tubiao">
              <img src="@/assets/homepage/icon-submitTemplate.png"
                   alt="">
            </div>
          </div>
        </div>
        <div class="right_welcome">
          <div class="text">
            <span>
              {{username}}您好！欢迎使用天天进步中考智能学伴教师端
            </span>
            <span>
              <div v-if="ifIpad">
                天天进步中考智能学伴是由广东善利智能科技有限公司自主研发，
                致力于通过人工智能让学生进行科学、高效、个性化备考的一款革命性的智能教育产品。
                教师端旨在帮助教师协助学生学习备考。
                <!-- 目前具有整卷测试、补短训练、弱点分析、学情分析等功能，助力学生发现学习重点，进行查漏补缺。 -->
              </div>
              <div v-else>
                天天进步中考智能学伴是由广东善利智能科技有限公司自主研发，
                致力于通过人工智能让学生进行科学、高效、个性化备考的一款革命性的智能教育产品。
                目前具有整卷测试、知识点训练、学情分析、错题本、优质课程等功能，助力学生发现学习重点，解决学习难点。
                教师端旨在帮助教师协助学生学习备考。
              </div>
            </span>
          </div>
          <div class="pic">
            <img src="@/assets/homepage/bg-head.png"
                 alt="">
          </div>
        </div>
      </div>

      <subject ref="subjectRef" />
      <div class="submit_warp">
        <div class="left">
          <allSubmit ref="allSubmitRef" />
        </div>
        <div class="right">
          <bing ref="bingRef"
                :datas="datas" />
        </div>
      </div>

      <div class="head">学生统计</div>

      <div class="student">
        <div class="item">
          <div class="icon">
            <img src="@/assets/homepage/new/all.png"
                 alt="">
          </div>
          <div class="text">
            学生总数
          </div>
          <div class="number">
            {{info.student_num}}
          </div>
        </div>

        <div class="item">
          <div class="icon">
            <img src="@/assets/homepage/new/do.png"
                 alt="">
          </div>
          <div class="text">
            学生做题数
          </div>
          <div class="number">
            {{info.student_question_num}}
          </div>
        </div>

        <div class="item">
          <div class="icon">
            <img src="@/assets/homepage/new/submit.png"
                 alt="">
          </div>
          <div class="text">
            总提交试卷数
          </div>
          <div class="number">
            {{info.student_paper_num}}
          </div>
        </div>

        <div class="item">
          <div class="icon">
            <img src="@/assets/homepage/new/change.png"
                 alt="">
          </div>
          <div class="text">
            总得分率变化
          </div>
          <div class="number">
            {{info.student_point_change}}
          </div>
        </div>
      </div>
      <StudentScoreAvg ref="StudentScoreAvgRef" />
      <div class="student_submit">
        <div class="left">
          <SubmitPaperNumber ref="SubmitPaperNumberRef" />
        </div>
        <div class="right">
          <PaperStatisyics ref="PaperStatisyicsRef" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//欢迎页
import welcome from './components/welcome/index'
import subject from './components/subject.vue'
import allSubmit from './components/tijiaoshu.vue'
import bing from './components/bing.vue'
import StudentScoreAvg from './components/studentScore/stu.vue'
// 学生试卷数
import SubmitPaperNumber from './components/studentScore/studentPaper.vue'
// 提交试卷统计
import PaperStatisyics from './components/studentScore/paperStatisyics.vue'

export default {
  name: 'homepage',
  data () {
    return {
      showWelcome: true,
      subjectList: [],
      home_id: '',
      state: {},
      info: {},
      school_id: '',
      teacherList: [],
      schoolList: [],
      ifIpad: false,
      jieList: [],
      // 等于0的话是刚进入页面，不触发学校改变事件
      num: 0,
      datas: [
        { name: '模板试卷数', value: 421 },
        { name: '教师试卷数', value: 445 },
        { name: '自由选题数', value: 526 },
        { name: '弱点训练数', value: 245 },
        { name: '讲义训练数', value: 246 }
      ],
      username: window.localStorage.getItem('userName'),
      scopeList: [
        { label: '一天', value: 1 },
        { label: '两天', value: 2 },
        { label: '三天', value: 3 },
        { label: '四天', value: 4 },
        { label: '五天', value: 5 },
        { label: '六天', value: 6 },
        { label: '一周', value: 7 },
        { label: '八天', value: 8 },
        { label: '九天', value: 9 },
        { label: '十天', value: 10 },
        { label: '十一天', value: 11 },
        { label: '十二天', value: 12 },
        { label: '十三天', value: 13 },
        { label: '两周', value: 14 },
        { label: '十五天', value: 15 },
        { label: '十六天', value: 16 },
        { label: '十七天', value: 17 },
        { label: '十八天', value: 18 },
        { label: '十九天', value: 19 },
        { label: '二十天', value: 20 },
        { label: '三周', value: 21 },
        { label: '二十二天', value: 22 },
        { label: '二十三天', value: 23 },
        { label: '二十四天', value: 24 },
        { label: '二十五天', value: 25 },
        { label: '二十六天', value: 26 },
        { label: '二十七天', value: 27 },
        { label: '四周', value: 28 },
        { label: '二十九天', value: 29 },
        { label: '三十天', value: 30 },
        { label: '三十一天', value: 31 },
      ]
    }
  },
  components: {
    subject, allSubmit, bing, StudentScoreAvg, SubmitPaperNumber, PaperStatisyics, welcome
  },
  watch: {
    school_id (newVal, oldVal) {

      this.getTeacherList()

      // 获取各个单点tab数据，
      this.getdandian()

      // 学生提交试卷数
      this.$refs.SubmitPaperNumberRef.getList()

      // 学生试卷统计
      this.$refs.PaperStatisyicsRef.getList()

      // 教师改卷统计
      this.$refs.subjectRef.getRuoDian()

      // 教师组卷统计
      this.$refs.allSubmitRef.getList()

      // 提交试卷统计
      this.$refs.bingRef.getList()
    },
    'state.width': {
      handler () {
        if (document.body.offsetWidth < 1300 && this.school_id) {
          // 判断是否显示欢迎页
          if (JSON.parse(window.localStorage.getItem('userInfo')).is_parttime == 1) {
            this.showWelcome = true;
            return
          } else {
            this.showWelcome = false;
          }
          setTimeout(() => {
            this.handleChildFunc()
          }, 1000);
        }
      },
      deep: true
    },
  },
  created () {
    this.state = this.$store.state
  },
  mounted () {
    if (document.body.clientWidth < 601) {
      this.ifIpad = true
    }

    // 判断是否显示欢迎页
    if (JSON.parse(window.localStorage.getItem('userInfo')).is_parttime == 1) {
      this.showWelcome = true;
      return
    } else {
      this.showWelcome = false;
    }

    this.$nextTick(() => {
      document.getElementsByClassName('view_warp')[0].style.background = '#F6F6F6'
    })
    this.getGraduated()
    this.getRole()
  },
  methods: {
    getRole () {
      this.$http({
        url: '/api/v1/public/homepage_role',
        method: 'get',
      }).then(res => {
        this.home_id = res.data.home_id
        this.getSchoolList()

      })
    },
    handleChildFunc () {
      this.$refs.SubmitPaperNumberRef.clear()

      // 学生试卷统计
      this.$refs.PaperStatisyicsRef.clear()
      //教师改卷统计
      this.$refs.subjectRef.clear()

      // 教师组卷统计
      this.$refs.allSubmitRef.clear()

      // 提交试卷统计
      this.$refs.bingRef.clear()

    },
    getGraduated () {
      this.$http({
        url: '/api/v1/public/graduated_lst',
        method: 'get'
      }).then(res => {
        this.jieList = res.data
      })
    },
    getdandian () {
      this.$http({
        url: '/api/v1/main/report_data',
        method: 'get',
        params: {
          school_id: this.school_id
        }
      }).then(res => {
        this.info = res.data
      })
    },
    getSchoolList () {
      this.$http({
        url: '/api/v1/main/get_user_school',
        method: 'get'
      }).then(res => {
        this.schoolList = res.data.list
        if (this.home_id == 1) {
          this.school_id = this.schoolList[0].school_id
        } else {
          this.school_id = JSON.parse(window.localStorage.getItem('userInfo')).edu_school_id
        }
        this.$refs.StudentScoreAvgRef.getHomeSubject()
      })
    },
    getTeacherList () {
      this.$http({
        url: '/api/v1/main/get_teacher',
        method: 'get',
        params: {
          school_id: this.school_id
        }
      }).then(res => {
        this.teacherList = res.data.list
        // 学生总得分率统计
        // this.getHomeSubject()
      })
    },
    // getHomeSubject () {
    //   this.$http({
    //     url: 'api/v1/main/home_subject',
    //     method: 'get'
    //   }).then(res => {
    //     this.subjectList = res.data.list
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  .checkSchool {
    display: flex;
    width: 300px;
    margin-left: 1%;
    align-items: center;
    span {
      width: 90px;
      font-weight: bold;
      font-size: 18px;
    }
    div {
      flex: 1;
    }
  }
  // img {
  //   width: 30px;
  // }
  div {
    font-size: 16px;
    letter-spacing: 1px;
  }
  .info_warp {
    width: 100%;
    height: 267px;
    display: flex;
    .left_tag {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      .tag {
        width: 46%;
        display: flex;
        margin: 2%;
        background: white;
        .number {
          flex: 5;
          margin: 15px;
          div {
            &:nth-child(1) {
              color: red;
              color: rgba(0, 0, 0, 0.85);
              font-weight: bold;
              font-size: 24px;
            }
            &:nth-child(2) {
              color: #999999;
              font-weight: 500;
              font-size: 16px;
              line-height: 30px;
            }
          }
        }
        .tubiao {
          flex: 3;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
    .right_welcome {
      flex: 1;
      display: flex;
      background: white;
      width: 46%;
      margin: 1%;
      .text {
        flex: 4;
        flex-wrap: wrap;
        span {
          display: block;
          &:nth-child(1) {
            font-size: 22px;
            font-weight: bold;
            margin: 3%;
          }
          &:nth-child(2) {
            font-size: 16px;
            margin: 1%;
            margin-left: 3%;
            color: #999999;
            line-height: 26px;
          }
        }
      }
      .pic {
        flex: 1;
        text-align: center;
        margin-top: 20px;
        img {
          width: 60px;
          height: 60px;
        }
      }
    }
    .right_welcome1 {
      flex: 1;
      display: flex;
      margin: 1% 1%;
      .tag {
        width: 50%;
        display: flex;
        background: white;
        margin-right: 2%;
        &:nth-child(2) {
          margin-left: 2%;
        }
        .number {
          flex: 5;
          margin: 15px;
          div {
            &:nth-child(1) {
              color: red;
              color: rgba(0, 0, 0, 0.85);
              font-weight: bold;
              font-size: 24px;
            }
            &:nth-child(2) {
              color: #999999;
              font-weight: 500;
              font-size: 16px;
              line-height: 30px;
            }
          }
        }
        .tubiao {
          flex: 3;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }
  .submit_warp {
    margin: 0.5%;
    display: flex;
    .left {
      flex: 1;
    }
    .right {
      flex: 1.5;
    }
  }
  .tag {
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    top: 0px;
    right: 0px;
    transition: all 0.2s ease;
    &:hover {
      position: relative;
      top: -2px;
      right: 2px;
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    }
  }

  .right_welcome {
    cursor: pointer;
    border-radius: 5px;
    top: 0px;
    right: 0px;
    transition: all 0.5s ease;
    &:hover {
      position: relative;
      top: -2px;
      right: 2px;
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    }
  }

  .head {
    font-size: 30px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
  .student {
    display: flex;
    .item {
      flex: 1;
      display: flex;
      align-items: center;
      margin: 1%;
      border-radius: 10px;
      cursor: pointer;
      position: relative;
      top: 0 px;
      right: 0 px;
      transition: all 0.2s ease;
      background: white;
      height: 101.3px;
      &:hover {
        position: relative;
        top: -2px;
        right: 2px;
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
      }
      .icon {
        img {
          margin-left: 10px;
          width: 52px;
          height: 52px;
        }
      }
      .text {
        text-indent: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #999999;
        width: 130px;
      }
      .number {
        flex: 1;
        text-align: right;
        margin-right: 10px;
        font-size: 24px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }

  .student_submit {
    display: flex;
    height: 40vh;
    .left {
      flex: 1.5;
    }
    .right {
      flex: 1;
    }
  }
}

@media screen and (max-width: 1200px) {
  * {
    font-size: 15px;
  }
  .checkSchool {
    display: flex;
    width: 300px;
    align-items: center;
    span {
      width: 100px;
    }
    flex: 1;
  }
  .info_warp {
    display: flex;
    height: 180px;
    overflow: hidden;
    .left_tag {
      width: 48%;
      display: flex;
      flex-wrap: wrap;
      text-indent: 5px;
      .tag {
        width: 47%;
        display: flex;
        background: white;
        border-radius: 10px;
        margin: 5px;
        &:hover {
          position: relative;
          top: -2px;
          right: 2px;
          box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
        }
        .number {
          flex: 1;
          div {
            height: 50%;
            display: flex;
            text-indent: 14px;
            &:nth-child(1) {
              align-items: flex-end;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.85);
              font-size: 20px;
            }
            &:nth-child(2) {
              font-size: 14px;
              color: #999999;
              font-weight: 500;
              align-items: flex-start;
              color: #999999;
            }
          }
        }
        .tubiao {
          width: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 40px;
          }
        }
      }
    }
    .right_welcome {
      width: 50%;
      display: flex;
      background: white;
      border-radius: 5px;
      margin: 5px 5px;
      &:hover {
        position: relative;
        top: -2px;
        right: 2px;
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
        .text {
          overflow-y: visible;
          overflow-x: hidden;
          background: #ffffff;
          &::-webkit-scrollbar {
            /* Chrome Safari */
            width: 0 !important;
          }
        }
      }
      .text {
        flex: 1;
        overflow: hidden;
        margin-left: 14px;
        span {
          &:nth-child(1) {
            display: block;
            font-weight: bold;
            line-height: 18px;
            margin-top: 9px;
            font-size: 16px;
            width: calc(100% - 10px);
            color: rgba(0, 0, 0, 0.85);
            white-space: nowrap; /*超出的空白区域不换行*/
            overflow: hidden; /*超出隐藏*/
            text-overflow: ellipsis; /*文本超出显示省略号*/
          }
          &:nth-child(2) {
            color: #999999;
            margin-top: 8px;
            display: inline-block;
            width: calc(100% - 40px);
            font-weight: 500;
            color: #999999;
            font-size: 14px;
            line-height: 30px;
            letter-spacing: 1px;
          }
        }
      }
      .pic {
        width: 74px;
        img {
          margin-top: 10px;
          width: 60px;
          height: 60px;
        }
      }
    }
  }
  .submit_warp {
    margin: 10px 1%;
    display: flex;
    .left {
      width: 360px;
      border-radius: 5px;
      flex-shrink: 0;
    }
    .right {
      flex: 1;
      margin-left: 10px;
      border-radius: 5px;
    }
  }
  .head {
    font-size: 18px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    margin: 20px 0 10px 0;
  }
  .student {
    display: flex;
    justify-content: space-between;
    height: 80px;
    margin: 0 1%;

    .item {
      width: 24%;
      border-radius: 5px;
      background: #ffffff;
      flex-shrink: 0;
      text-indent: 14px;
      overflow: hidden;
      position: relative;
      &:hover {
        position: relative;
        top: -2px;
        right: 2px;
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
      }
      .icon {
        position: absolute;
        right: 21px;
        img {
          margin-top: 18px;
          width: 43px;
          height: 43px;
        }
      }
      .text {
        position: absolute;
        top: 42px;
        font-weight: 500;
        color: #999999;
        font-size: 14px;
      }
      .number {
        position: absolute;
        font-size: 20px;
        top: 14px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
  .student_submit {
    display: flex;
    height: 40vh;
    .left {
      flex: 1.9;
    }
    .right {
      flex: 1.5;
    }
  }
  ::v-deep .el-input__inner {
    font-size: 15px !important;
  }
}
</style>  