<template>
  <div class="subject_warp">
    <div class="form">
      <div class="date">
        <span>
          教师答疑统计
        </span>
        <el-date-picker v-model="date"
                        style="margin:10px 0"
                        @change="clear"
                        @focus="$forbid"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
        <span class="fanwei">
          范围
        </span>
        <!-- 默认7天 -->
        <el-select style="width:110px"
                   class="selectFanwei"
                   v-model="scope"
                   @change="clear"
                   clearable
                   placeholder="范围">
          <el-option v-for="item in $parent.scopeList"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
        <div class="checkteacher">
          <el-select style="width:110px"
                     v-model="edu_user_id"
                     v-if="$parent.home_id == 2 || $parent.home_id == 1"
                     @change="clear"
                     clearable
                     filterable
                     placeholder="请选择">
            <el-option v-for="item in $parent.teacherList"
                       :key="item.edu_user_id"
                       :label="item.edu_user_name"
                       :value="item.edu_user_id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="subject">
        <div class="teacher">
          <el-select style="width:110px"
                     v-model="edu_user_id"
                     v-if="$parent.home_id == 2 || $parent.home_id == 1"
                     @change="clear"
                     clearable
                     filterable
                     placeholder="请选择">
            <el-option v-for="item in $parent.teacherList"
                       :key="item.edu_user_id"
                       :label="item.edu_user_name"
                       :value="item.edu_user_id">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div id="teacherTtatistics"
         :style="{width: '100%', height: '40vh'}"></div>
  </div>
</template>

<script>
import { frontDay, frontNowDay } from '@/utils/date.js'

export default {
  data () {
    return {
      subjectList: [],
      teacher: '',
      date: [],
      // date: [frontDay(7, false), frontNowDay(0, false)],
      scope: 7,
      edu_user_id: '',
      list: [],
      value: [],
      label: []
    }
  },
  created () { },
  methods: {
    clear () {
      let myChart = this.$echarts.init(document.getElementById('teacherTtatistics'))
      myChart.dispose()
      this.getList()
    },
    drawLine () {
      var myChart = this.$echarts.init(document.getElementById('teacherTtatistics'));
      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.label
        },
        grid: {
          bottom: '10%',
          top: '15%'
        },
        yAxis: {
          type: 'value',
          min: 0
        },
        color: ['#3F9EFF'],
        series: [
          {
            data: this.value,
            type: 'line',
            areaStyle: {}
          }
        ]
      })
    },
    getList () {
      let params = {
        scope: this.scope,
        edu_user_id: this.edu_user_id,
        school_id: this.$parent.school_id,
      }
      if (this.date) {
        params.start_time = this.date[0]
        params.end_time = this.date[1]
      }
      this.$http({
        url: '/api/v1/main/get_teacher_disscuss',
        method: 'get',
        params
      }).then(res => {
        this.list = res.data.list

        let label = []
        let value = []
        this.list.forEach(item => {
          label.push(`${item.start_time}~${item.end_time}`)
          value.push(item.count)
        })
        this.value = value
        this.label = label
        this.$nextTick(() => {
          this.drawLine()
        })
      })
    },

  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  .isActive {
    border: 1px solid #3f9eff !important;
    color: #3f9eff !important;
  }
  .subject_warp {
    background: white;
    padding: 10px;
    padding-bottom: 8px;
    margin: 1%;
  }
  .subject_list {
    border: 1px solid #d9d9d9;
    display: inline-block;
    span {
      display: inline-block;
      border-right: 1px solid #d9d9d9;
      padding: 5px 10px;
      cursor: pointer;
      color: #000000;
    }
  }
  .form {
    display: flex;
    .date {
      flex: 1;
      span {
        width: 120px;
        font-weight: bold;
        color: rgba(8, 8, 8, 0.85);
        font-size: 16px;
      }
    }
    .subject {
      display: flex;
      align-items: center;
    }
  }
  ::v-deep.el-range-editor.el-input__inner {
    width: 230px !important;
  }
  .checkteacher {
    display: none !important;
  }
}
@media screen and (max-width: 1200px) {
  .subject_warp {
    overflow: hidden;
    background: white;
    border-radius: 5px;
    width: 100%;
    .form {
      position: relative;
      .date {
        font-size: 14px;
        margin-left: 10px;
        span {
          &:nth-child(1) {
            display: block;
            width: 100%;
            font-size: 16px;
            font-weight: bold;
            color: rgba(8, 8, 8, 0.85);
            text-align: center;
            margin: 10px 0;
          }
        }
        .fanwei {
          margin: 0 5px;
          display: inline-block;
        }
        .el-date-editor {
          width: 220px;
        }
        .selectFanwei {
          width: 80px !important;
        }
        .checkteacher {
          display: inline-block;
          margin-left: 15px;
        }
      }
    }
  }
  #teacherTtatistics {
    height: 240px !important;
  }
  .subject {
    display: none;
  }
}
</style>